<template>
    <div class="auto-capture-container animated fadeIn faster">
        <div class="auto-capture-management-titlebar">
            <ar-titlebar
                campaign="Auto-Capture Management Page"
                subject="Listings"
                a_class="fit-content"
                :from_count="from_page_count"
                :to_count="to_page_count"
                :max_count="total"
                is_listings
                :has_image="false"
            >
                <!-- :max_count="total" -->
                <template v-slot:search-form>
                    <div class="form-inline">
                        <div class="a-input-group" style="display: flex;align-items: center;justify-content: flex-end;gap:10px;">
                            <!-- <ar-textfield
                                label        ="Date Captured"
                                a_type       ="date"
                                v-model.trim ="date_capture.filterDateCaptured"
                                :max         ="today"
                            /> -->
                            <ar-select
                                :first_element_as_default="true"
                                v-model="searchBy"
                                :options="searchBy_options"
                                a_class="no-margin-bottom"
                                a_style="width: 200px; "
                            ></ar-select>
                            <template v-if="searchBy != 4">
                                <ar-textfield 
                                    placeholder         ="Search here..." 
                                    type                ="text"
                                    a_class             ="mr-1"
                                    a_style             ="width: 300px;"
                                    :icon               ="search.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                                    v-model             ="search"
                                    @click              ="clearKeywords();get();getStatus_count();"
                                    @keyup.enter.native ="get()"
                                    title="Press 'Enter' to search all the Auto Capture Listings."
                                />
                            </template>
                            <template v-if="searchBy == 4">
                                <ar-textfield
                                    label="From"
                                    a_type="date"
                                    a_class="remove-date-spinner"
                                    a_style="padding-right: 3px;"
                                    v-model="min_date_added"
                                    :max="today"
                                    labelClass="mr-2"
                                />
                                <ar-textfield
                                    label="To"
                                    a_type="date"
                                    a_class="remove-date-spinner"
                                    a_style="padding-right: 3px;"
                                    :disabled="min_date_added == ''"
                                    v-model="max_date_added"
                                    :min="min_date_added"
                                    :max="today"
                                    labelClass="mr-2"
                                />
                            </template>
                        </div>
                    </div>
                </template>
            </ar-titlebar>
            <div class="position-relative d-flex justify-content-between align-items-center filter-bar" :class="{'pl-4': !hasSelection || $featureAccess.isRestricted('a-container-action-button'), 'p-0': hasSelection && !$featureAccess.isRestricted('a-container-action-button')}">
                <div class="d-flex justify-content-between align-items-center selection-bar mr-5" :class="{'has-selection': hasSelection && !$featureAccess.isRestricted('a-container-action-button')}">
                    <div class="w-100" v-show="!hasSelection || $featureAccess.isRestricted('a-container-action-button')">
                        <div class="animated fadeIn faster">
                            <div class="dropdown d-inline-block">
                                <a href="javascript:void(0);" id="filterByCampaign" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button :disabled="campaigns_inAutoCapture.length == 0" class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px; width:12px; height:12px;"
                                            src="/static/svg/Nav_Campaign_Active.svg" alt="campaign"> {{ $t(`Filter by - ${selected_campaign}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByCampaign">
                                    <a v-for="camp in modified_campaignForFilterBy" :key="camp.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="campaign = camp.value" style="gap: 12px;">
                                        <img :src="camp.logo" @error="errorCampaignImage($event)" :alt="`${camp.label} Logo`" style="width: 18px; height: 18px; border-radius: 2px;">{{ $t(camp.label) }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByResearcher" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button :disabled="researchers_inAutoCapture.length == 0" class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px;"
                                            src="/static/svg/Auto Campaign Admin/researchers.svg" alt="researcher"> {{ $t(`Filter by - ${selected_researcher}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByResearcher">
                                    <a v-for="r in modified_researcherForFilterBy" :key="r.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="researcher = r.value" style="gap: 12px;">
                                        <img :src="r.logo" :alt="`${r.label} Logo`" style="width: 18px; height: 18px; border-radius: 2px;">{{ $t(r.label) }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByStatus" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button :disabled="isStatus_loading" class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px;"
                                            src="/static/svg/Auto Campaign Admin/filter-status.svg" alt="status"> {{ $t(`Filter by - ${selected_status}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu filterby-status" aria-labelledby="filterByStatus">
                                    <a v-for="stat in statuses" :key="stat.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="status = stat.value" style="gap: 6px;">
                                        <div class="d-flex align-items-center justify-content-between " style="width: calc(100% + 30px)">
                                            <div class="d-flex align-items-center justify-content-start w-100" style="gap:6px;">
                                                <span :style="`width: 6px !important; height:6px !important;border-radius: 100%; background: ${stat.color};`" />{{ $t(stat.label) }}
                                            </div>
                                            <p class="status-count m-0" 
                                                :class="{  
                                                    'pending': stat.label.toLowerCase() == 'pending',
                                                    'processing': stat.label.toLowerCase() == 'processing',
                                                    'completed-with-issues': stat.label.toLowerCase() == 'completed with issues' ? 'completed_with_issues' : '',
                                                    'failed': stat.label.toLowerCase() == 'failed',
                                                    'success': stat.label.toLowerCase() == 'success',
                                                    'all-statuses': stat.label.toLowerCase() == 'all statuses',
                                                }"
                                            >
                                                {{ stat.count }}
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByQA" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button :disabled="researchers_inAutoCapture.length == 0" class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px;"
                                            src="/static/svg/Auto Campaign Admin/researchers.svg" alt="qa"> {{ $t(`Filter by - ${selected_QA}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByQA">
                                    <a v-for="r in modified_QAforFilterBy" :key="r.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="qa = r.value" style="gap: 12px;">
                                        <img :src="r.logo" :alt="`${r.label} Logo`" style="width: 18px; height: 18px; border-radius: 2px;">{{ $t(r.label) }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100" v-show="hasSelection && !$featureAccess.isRestricted('a-container-action-button')">
                        <div 
                            class="d-flex justify-content-between align-items-center w-100 a-container-action-button"
                            v-restrict="'a-container-action-button'"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <b class="text-uppercase">Actions</b>
                                <span style="border: 1px solid #ccc;height: 20px; width: 1px;margin: 0px 23px 0 12px;" />
                                <a 
                                    href="javascript:void(0)"
                                    role="button"
                                    class="combo-btn mr-4 a-action-button-unselect-all"
                                    v-restrict="'a-action-button-unselect-all'"
                                    @click="initializeSelectionCache()"
                                >
                                    <img src="/static/svg/Main-feature.svg" alt="unselect"/>Unselect all
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    role="button"
                                    class="combo-btn delete mr-4 a-action-button-delete"
                                    v-restrict="'a-action-button-delete'"
                                    @click="openModal('delete-auto-capture-modal')"
                                >
                                    <img src="/static/svg/admin/Auto Capture Management/delete.svg" alt="delete"/>Delete
                                </a>
                                <a 
                                    href="javascript:void(0)"
                                    role="button"
                                    class="combo-btn rescan mr-4 a-action-button-rescan"
                                    v-restrict="'a-action-button-rescan'"
                                    @click="openModal('multiple-rescan-modal')"><img src="/static/svg/admin/Auto Capture Management/rescan.svg" alt="rescan"/>Rescan
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    role="button"
                                    class="combo-btn revert mr-4 a-action-button-revert-to-researcher"
                                    v-restrict="'a-action-button-revert-to-researcher'"
                                    @click="openModal('revert-to-researcher-modal')"><img src="/static/svg/admin/Auto Capture Management/revert.svg" alt="revert"/>Revert to Researcher
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    role="button"
                                    class="combo-btn reassign mr-4 a-action-button-reassign-researcher"
                                    v-restrict="'a-action-button-reassign-researcher'"
                                    @click="openModal('reassign-modal')"><img src="/static/svg/admin/Auto Capture Management/reassign.svg" alt="reassign"/>Reassign Researcher
                                </a>
                            </div>
                            <div class="has-selection-selected-count">
                                <img src="/static/svg/auto-assignment-bulk/selection-count.svg" alt="count"/>Selected {{ hasSelection }} Auto Capture(s)
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end" style="gap: 10px; margin-right: 21px;height: 100%;">
                    <ar-select
                        :first_element_as_default="true"
                        v-model="page_size"
                        :options="showNumberOfAutoCaptures_options"
                        a_class="no-margin-bottom"
                        a_style="width: 150px; "
                    ></ar-select>
                    <span style="border: 1px solid #eee;height: 40px; width: 1px;margin: 0px 12px;" />
                    <ar-pagination-v2
                        :last-page="last_page"
                        v-model="page"
                        style="width: 110px;align-items: center;margin-right: 8px;"
                    ></ar-pagination-v2>
                </div>
            </div>
        </div>
        <main class="main-con">
            <template>
                <table class="table-scroll">
                    <thead>
                        <tr>
                            <th class="col-1 text-center">
                                <ar-check-list 
                                    id="selec-all-auto-capture"
                                    v-model="selectAll"
                                    :value="false"
                                    :is-item="false"
                                    @click="multiSelect()"
                                    class="position-relative d-flex justify-content-center"
                                    style="height: 20px; top: 2px;"
                                />
                            </th>
                            <th class="col-1 text-left" scope="col" @click="updateTableOrder('campaign')">
                                Campaigns
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'campaign'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('listing_id')">
                                Listing ID
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'listing_id'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('seller_name')">
                                Seller Name
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'seller_name'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-2 text-center" scope="col" @click="updateTableOrder('seller_url')">
                                Seller URL
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'seller_url'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-2 text-center" scope="col" @click="updateTableOrder('product_url')">
                                Product URL
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'product_url'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('researcher')">
                                Researcher
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'researcher'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('date_captured')">
                                Date Captured
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'date_captured'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('status')">
                                Status
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'status'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col">
                                Actions
                                <!-- <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'actions'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div> -->
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <div v-if="!filtered_autoCaptures.result.length" class="w-100 h-100 d-flex align-items-center justify-content-center">
                            <img style="min-width: 300px;" src="/static/svg/NoData.svg" alt="No Data Found">
                        </div>
                        <template v-else>
                            <div 
                                v-for="(auto_capture, index) in filtered_autoCaptures.result"
                                :key="index"
                                class="table-item"
                                :class="{'isHovered': hovered_index == index, 'isClickedAction': hasClicked_actionOptions && selected_autoCapture.id == auto_capture.id && hasShowClass}"
                            >
                                <tr class="justify-content-center">
                                    <td class="col-1 text-center">
                                        <ar-check-list 
                                            :id="`select-auto-capture--${auto_capture.id}`"
                                            :name="`select-auto-capture--checklist`"
                                            v-model="selectedAutoCaptures[page]"
                                            :value="auto_capture.id"
                                            :is-item="false"
                                            class="position-relative d-flex justify-content-center"
                                            style="height: 20px; top: 2px;"
                                            @click="selectedAutoCapturesClean.includes(auto_capture.id) ? delete selectedAutoCaptures_data[auto_capture.id] : selectedAutoCaptures_data[auto_capture.id] = auto_capture"
                                        />
                                    </td>
                                    <td class="col-1 text-left">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div class="d-flex align-items-center justify-content-start" style="gap: 6px;width: 100%;" :title="auto_capture?.campaign?.name">
                                                <img :src="$parent.getImageCDN('campaigns',auto_capture?.campaign?.name || 'Default')" @error="errorCampaignImage($event)" :alt="auto_capture?.campaign?.name" style="width: 20px; height: 20px;border-radius: 4px;">
                                                <p class="m-0 truncate">
                                                    {{auto_capture?.campaign?.name}}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;" :title="auto_capture?.listing_id">
                                            <p class="m-0 truncate">
                                                {{auto_capture?.listing_id}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;" :title="auto_capture?.listing?.seller?.name">
                                            <p class="m-0 truncate">
                                                {{auto_capture?.listing?.seller?.name}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-2 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;" :title="auto_capture?.listing?.seller?.url">
                                            <p class="m-0 truncate">
                                                {{auto_capture?.listing?.seller?.url}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-2 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;" :title="auto_capture?.listing?.url">
                                            <p class="m-0 truncate">
                                                {{auto_capture?.listing?.url}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;" :title="`${auto_capture?.listing?.user?.first_name} ${auto_capture?.listing?.user?.last_name}`">
                                            <p class="m-0 truncate">
                                                {{`${auto_capture?.listing?.user?.first_name} ${auto_capture?.listing?.user?.last_name}`}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;" :title=" auto_capture?.listing?.info?.[0]?.value ?? 'No Date Captured'">
                                            <p class="m-0 truncate">
                                                {{ auto_capture?.listing?.info?.[0]?.value ?? 'No Date Captured'}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <p class="m-0 status"
                                                :class="{
                                                    'processing': auto_capture?.status?.toLowerCase() == 'processing',
                                                    'pending': auto_capture?.status?.toLowerCase() == 'pending',
                                                    'completed-with-issues': auto_capture?.status?.toLowerCase() == 'completed_with_issues',
                                                    'success': auto_capture?.status?.toLowerCase() == 'success',
                                                }"
                                                :title="auto_capture?.status?.toLowerCase() == 'completed_with_issues' ? 'COMPLETED WITH ISSUES' : auto_capture?.status"
                                            >
                                                {{auto_capture?.status?.toLowerCase() == 'completed_with_issues' ? 'COMPLETED WITH ISSUES' : auto_capture?.status}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center position-relative">
                                        <div class="d-flex align-items-center justify-content-center position-relative"  style="gap: 12px;">
                                            <img
                                                title="Click to View Actions"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                id="ActionOptions"
                                                src="/static/svg/admin/Auto Capture Management/action.svg"
                                                alt="Action Icon"
                                                style="cursor:pointer;width: 28px;height:28px;padding: 4px;"
                                                @click="selected_autoCapture = auto_capture; hasClicked_actionOptions = true;setActionOptionRef(index);"
                                            >
                                            <div 
                                                aria-labelledby="ActionOptions"
                                                class="dropdown-menu action-options"
                                                ref="actionOptionRef"
                                                id="ActionOptionsDropdown"
                                            >
                                                <a v-for="act in action_options" :key="act.value" class="dropdown-item"
                                                    href="javascript:void(0);" @click="action = act.value;action_openModal(act.value)" style="gap: 14px;font-size: 14px;">
                                                    <img :src="act.logo" :alt="`${act.label} Logo`" style="width: 16px; height: 16px; border-radius: 2px;">{{ $t(act.label) }}
                                                </a>
                                            </div>
                                            <!-- <img 
                                                src="/static/svg/admin/Auto Capture Management/action-rescan.svg"
                                                style="cursor: pointer;width: 24px; height: 24px;border-radius: 100%;"
                                                @click="selected_autoCapture = auto_capture;openModal('rescan-single-modal');"
                                                title="Rescan Auto Capture"
                                            >
                                            <img
                                                :src="`/static/svg/delete-actions.svg`"
                                                alt="Disable"
                                                style="cursor: pointer;width: 24px; height: 24px;border-radius: 100%;"
                                                @click="selected_autoCapture = auto_capture; openModal('delete-single-auto-capture-modal');"
                                                title="Delete Auto Capture"
                                            >
                                            <img
                                                :src="`/static/svg/admin/Auto Capture Management/revert.svg`"
                                                alt="Revert"
                                                style="cursor: pointer;width: 24px; height: 24px;border-radius: 100%;"
                                                @click="selected_autoCapture = auto_capture; openModal('individual-revert-to-researcher-modal');"
                                                title="Rescan Auto Capture"
                                            >
                                            <img
                                                :src="`/static/svg/admin/Auto Capture Management/reassign.svg`"
                                                alt="Revert"
                                                class="width: 24px; height: 24px;border-radius: 100%;"
                                                style="cursor: pointer;"
                                                @click="selected_autoCapture = auto_capture; openModal('reassign-modal');"
                                                title="Reassign Auto Capture"
                                            > -->
                                        </div>
                                    </td>
                                </tr>
                            </div>
                        </template>
                    </tbody>
                </table>
            </template>
        </main> 
        <ar-dialog id="rescan-single-modal" custom_minwidth="800px">
            <div class="d-flex flex-column align-items-center justify-content-start position-relative" style="gap:10px;">
                <div class="position-relative d-flex flex-column align-items-start justify-content-start w-100" style="padding-bottom: 25px;">
                    <p class="m-0" style="font-size: 24px;font-weight: bold;color:#480048;">Rescan this Listing</p>
                    <p class="m-0">You may update the existing keyword.</p>
                    <img style="height: 150px; position:absolute; bottom:0px; right:0px;" src="/static/svg/admin/Auto Capture Management/human-inspector.png" alt="Info Icon">
                </div>

                <div class="w-100 d-flex flex-column align-items-start justify-content-start" style="gap: 18px;">
                    <div class="w-100">
                        <div class="d-flex align-items-center justify-content-center" style="gap:4px;padding:4px 8px;border-radius:4px;background-color: #84a8e1;color: #2D599E;font-weight: bold;width:fit-content;margin-bottom: 5px;">
                            <img style="width: 12px; height: 12px;" src="/static/svg/admin/Auto Capture Management/sellerurl.svg" alt="Product URL Icon">
                            <p class="m-0" style="font-size: 12px;">STORE URL</p>
                        </div>
                        <ar-textfield
                            a_type="textarea"
                            :placeholder="selected_autoCapture?.url"
                            class="modified-textarea"
                            a_style="height: 120px; resize: none;"
                            disabled
                        />
                    </div>
                    <div class="w-100">
                        <div class="d-flex align-items-center justify-content-start" style="gap:4px;padding:4px 8px;border-radius:4px;background-color: #ffbfac;color: #FF5722;font-weight: bold;width:fit-content;margin-bottom: 5px;">
                            <img style="width: 12px; height: 12px;" src="/static/svg/admin/Auto Capture Management/producturl.svg" alt="Product URL Icon">
                            <p class="m-0" style="font-size: 12px;">PRODUCT URL</p>
                        </div>
                        <ar-textfield
                            a_type="textarea"
                            :placeholder="selected_autoCapture?.listing?.url"
                            class="modified-textarea"
                            a_style="height: 120px; resize: none;"
                            disabled
                        />
                    </div>
                    <div class="w-100">
                        <div class="d-flex align-items-center justify-content-start" style="gap:4px;padding:4px 8px;border-radius:4px;background-color: #ce94ce;color: #480048;font-weight: bold;width:fit-content;margin-bottom: 5px;">
                            <img style="width: 12px; height: 12px;" src="/static/svg/admin/Auto Capture Management/keywords.svg" alt="Product URL Icon">
                            <p class="m-0" style="font-size: 12px;">KEYWORD</p>
                        </div>
                        <ar-textfield
                            v-model="selected_autoCapture.keyword"
                            a_type="textarea"
                            placeholder="Input here..."
                            class="modified-textarea"
                            a_style="height: 48px; resize: none;"
                        />
                    </div>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                    @click="resetActionOptionsData();"
                >
                    Cancel
                </ar-button>
                <ar-button @click="rescan(selected_autoCapture.id,selected_autoCapture.keyword,true)">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="revert-to-researcher-modal">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-info.svg" alt="Info Icon">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;">Revert to Researcher!</p>
                    <p class="m-0" style="font-size: 16px;width: 90%;">You are about to return {{ selectedAutoCapturesClean.length > 1 ? 'these listings' : 'this listing' }} to original User as Work In Progress. Confirm by clicking on the <b>"Proceed"</b> button.</p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    Cancel
                </ar-button>
                <ar-button @click="batchRevert(selectedAutoCapturesClean)">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="individual-revert-to-researcher-modal">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-info.svg" alt="Info Icon">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;">Revert to Researcher!</p>
                    <p class="m-0" style="font-size: 16px;width: 90%;">You are about to return this listing to original User as Work In Progress. Confirm by clicking on the <b>"Proceed"</b> button.</p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                    @click="resetActionOptionsData();"
                >
                    Cancel
                </ar-button>
                <ar-button @click="batchRevert([selected_autoCapture.id])">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="multiple-rescan-modal">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-info.svg" alt="Info Icon">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p v-if="selectedAutoCapturesClean.length > 1" class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;">Rescan these Listings</p>
                    <p v-else class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;">Rescan this Listing</p>
                    <p class="m-0" style="font-size: 16px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    Cancel
                </ar-button>
                <ar-button @click="batchRescan(selectedAutoCapturesClean)">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="delete-auto-capture-modal">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-error.svg" alt="Error SVG">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p v-if="selectedAutoCapturesClean.length > 1" class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;"><b style="color: #DC3C40;">Delete</b> these Listings</p>
                    <p v-else class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;"><b style="color: #DC3C40;">Delete</b> this Listing</p>
                    <p class="m-0" style="font-size: 16px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    Cancel
                </ar-button>
                <ar-button @click="batchDeleteAutoCapture(selectedAutoCapturesClean)">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="delete-single-auto-capture-modal">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-error.svg" alt="Error SVG">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;"><b style="color: #DC3C40;">Delete</b> this Listing</p>
                    <p class="m-0" style="font-size: 16px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                    @click="resetActionOptionsData();"
                >
                    Cancel
                </ar-button>
                <ar-button @click="deleteAutoCapture(selected_autoCapture.id,true)">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <!-- <ar-dialog id="reassign-researcher-modal">
            <div class="d-flex align-items-start justify-content-start" style="gap: 42px;height:450px;">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 10px;">
                    <div class="d-flex flex-column align-items-start justify-content-start">
                        <h3 style="color: #000000DE;" class="m-0">Reassign Researcher</h3>
                        <p style="font-size: 14px;color: #515365;">Select different user to assign on <b style="color: #480048;">listing</b>.</p>
                        <ar-textfield 
                            placeholder         ="Search User here..." 
                            type                ="text"
                            a_class             ="mr-1"
                            a_style             ="width: 300px;"
                            :icon               ="researcher_modal_search.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                            v-model             ="researcher_modal_search"
                        />
                    </div>
                    <div
                        class="d-flex flex-column align-items-center justify-content-start" 
                        style="height: 250px;width: 100%;border:1px solid #ced4da;overflow: auto;gap: 5px;padding:10px 5px;"
                    >
                        <div
                            v-for="(researcher, index) in allResearchers"
                            :key="index"
                            style="width: 100%;gap: 10px;padding: 12px;font-size: 14px;text-align: left;border-radius: 4px;"
                            class="d-flex align-items-center justify-content-start"
                            :style="[selected_reassign_researcher.id != researcher.id ? {'border-bottom': '1px solid #ced4da'} : {'border':'1px solid #4800485C','background-color': '#F6E6EE'} ]"
                            @click="selected_reassign_researcher = researcher"
                        >
                            <img style="height: 30px;width: 30px;" src="/static/svg/User_Researcher.svg" alt="User Researcher Icon">
                            <p class="m-0">
                                {{researcher.first_name}} {{ researcher.last_name ?? '' }}
                            </p>
                        </div>
                    </div>
                </div>
                <span style="height:100%;border:.5px solid #ced4da;opacity: 90%;"></span>
                <div>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    Cancel
                </ar-button>
                <ar-button>
                    Proceed Reassigning
                </ar-button>
            </template>
        </ar-dialog> -->
        <ar-dialog
            id="reassign-modal"
            :custom_maxwidth="'100%'"
            :custom_width="reassignStatuses.results.length || (selected_reassign_researcher.id != null && selected_reassign_researcher.id != undefined) ? '1200px' : '430px'"
            :mdStyle="{ 'transition' : '0.3s ease' }"
            :xxLarge="!!reassignStatuses.results.length || !!(selected_reassign_researcher.id != null && selected_reassign_researcher.id != undefined)"
            :has_footer="false"
            data-backdrop="static"
        >
            <div v-if="!reassignStatuses.results.length" class="d-flex text-left justify-content-between withReason">
                <div class="pr-4" :class="{'border-right': (selected_reassign_researcher.id != null && selected_reassign_researcher.id != undefined), 'w-100': !(selected_reassign_researcher.id != null && selected_reassign_researcher.id != undefined)}">
                    <div class="mb-2 reassign-left-heading" :class="{'text-center': !(selected_reassign_researcher.id != null && selected_reassign_researcher.id != undefined)}">
                        <div class="d-flex flex-column align-items-start justify-content-start">
                            <h3 style="color: #000000DE;" class="m-0">Reassign Researcher</h3>
                            <p style="font-size: 14px;color: #515365;">Select different user to assign on <b style="color: #480048;">listing</b>.</p>
                            <ar-textfield 
                                placeholder         ="Search User here..." 
                                type                ="text"
                                a_class             ="mr-1"
                                a_style             ="width: 330px;"
                                :icon               ="researcher_modal_search.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                                v-model             ="researcher_modal_search"
                                @click              ="researcher_modal_search=''"
                            />
                        </div>
                    </div>

                    <div class="left-con">
                        <div class="row">
                            <div class="col-md-12">
                                <div
                                    class      ="col-md-12"
                                    style="padding: 0px;"
                                >
                                    <div
                                        class="d-flex flex-column align-items-center justify-content-start" 
                                        style="height: 250px;width: 100%;border:1px solid #ced4da;overflow: auto;gap: 5px;padding:10px 5px;"
                                    >
                                        <template v-if="allResearchers.length">
                                            <div
                                                v-for="(researcher, index) in filtered_allResearchers"
                                                :key="index"
                                                style="width: 100%;gap: 10px;padding: 12px;font-size: 14px;text-align: left;border-radius: 4px;border:1px solid transparent;"
                                                class="d-flex align-items-center justify-content-start position-relative cursor-pointer"
                                                :style="[selected_reassign_researcher.id != researcher.id ? {'border-bottom': '1px solid #ced4da !important'} : {'border':'1px solid #4800485C','background-color': '#F6E6EE'} ]"
                                                @click="selected_reassign_researcher.id == researcher.id ? selected_reassign_researcher = {} : selected_reassign_researcher = researcher"
                                            >
                                                <img style="height: 30px;width: 30px;" src="/static/svg/User_Researcher.svg" alt="User Researcher Icon">
                                                <p class="m-0">
                                                    {{researcher.first_name}} {{ researcher.last_name ?? '' }}
                                                </p>
                                                <i 
                                                    v-if="selected_reassign_researcher.id == researcher.id"
                                                    class="fas fa-check-circle"
                                                    style="height: 16px;width: 16px;color:#601848;position:absolute;right: 10px; top:50%;transform: translateY(-50%);"
                                                    alt="check circle icon"
                                                />
                                            </div>
                                        </template>
                                        <span v-else>Oops! No account(s) found. Consider changing the search query.</span>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <ar-select
                                        type        ="search" 
                                        placeholder ="Select Status" 
                                        label       ="Status" 
                                        id          ="reassign-status-select"
                                        v-model     ="reassignStatuses.selected" 
                                        :options    ="reassignStatusOptions" 
                                        :disabled   ="!reassignStatusOptions.length"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-0 pl-4 reassign-right-con" v-if="(selected_reassign_researcher.id != null && selected_reassign_researcher.id != undefined)">
                    <h4 class="m-0" style="color: #480048;">Predefined Reasons</h4>
                    <div class="reassign-right-heading d-flex justify-content-between align-items-center">
                        <h5> Selected ({{ selectedAutoCapturesClean.length }}) listing(s)
                            <span class="mt-1 d-block font-weight-normal">Please select reason(s) to continue reassignment</span>
                        </h5>
                    </div>

                    <div class="right-con pt-3">
                        <div class="reassign-to-wip-notice mt-4 mb-4" v-show="isReassignWIP">
                            <a class="notice-img">
                                <img onerror="this.src='/static/svg/Notice_SVG.svg'" src="/static/svg/Notice_SVG.svg" alt="woman-smiling-with-notice">
                            </a>
                            <h6>Notice</h6>
                            <p>These data has already been processed and validated by a recognized third party.</p>
                        </div>
                        <ar-reason-selection
                            :reasons    ="predefined_reasons"
                            key         ="reassign-to-wip-modal-reasons"
                            :is-loading ="is_loading"
                            is-batch-modal
                        />
                    </div>
                </div>
            </div>
            <template v-else>
                <h5 class="d-inline-block ml-2 align-middle">
                    Reassignment Status
                    <span class="d-block font-weight-normal mt-1">Below are the unsuccessful reassignment of listings in the request and their reasons.</span>
                </h5>
                <table class="table-scroll mb-2">
                    <thead>
                        <template >
                            <tr>
                                <th class="col-md-1" scope="col">LID</th>
                                <th class="col-md-5" scope="col">Seller Name</th>
                                <th class="col-md-6" scope="col">Message (hover to see message)</th>
                            </tr>
                        </template>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in reassignStatuses.results" :key="index">
                                <td class="col-md-1 text-bold" scope="row">
                                    <span class="status not_found">{{ item.reason.lid }}</span>
                                </td>
                                <td class="col-md-5">
                                    <span class="d-block text-truncate">
                                        {{ item.reason.seller }}
                                    </span>
                                </td>
                                <td class="col-md-6">
                                    <span class="d-block text-truncate" :title="item.reason.message.message == `Error. The researcher aren't assigned to the specified campaign.` ? `Error. The researcher isn't assigned to the specified campaign.` : item.reason.message.message">{{ item.reason.message.message == `Error. The researcher aren't assigned to the specified campaign.` ? `Error. The researcher isn't assigned to the specified campaign.` : item.reason.message.message }}</span>
                                </td>
                        </tr>
                    </tbody>
                </table>

                <div class="btn-con float-right mt-3">
                    <ar-button 
                        outlined 
                        data-dismiss="modal"
                        style="color: #202229; border-color: #202229;"
                        @click="resetReassignData();selected_reassign_researcher={};closeModal('reassign-modal');resetActionOptionsData();"
                    >Cancel</ar-button>
                    <ar-button class="ml-2" @click="resetReassignData()">
                        Go Back
                    </ar-button>
                </div>
            </template>
            
            <template v-slot:buttons v-if="!reassignStatuses.results.length">
                <ar-button
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    :outlined    ="true"
                    @click="resetReassignData();selected_reassign_researcher={};closeModal('reassign-modal');resetActionOptionsData();"
                >
                    Cancel
                </ar-button>
                <ar-button :disabled="isDisableToReassign" @click="batchReassign(reassignStatuses.selected, selected_reassign_researcher.id)">
                    Reassign
                </ar-button>
            </template>
        </ar-dialog>
    </div>
</template>
<script>
import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';
import { showBackdrop, hideBackdrop, getHeaders } from '@/utils/common';
import { getGuiSession } from '@/utils/session';
import { ListingStatus, } from '../../../utils/constants';
import { mapActions } from 'vuex'

import CheckList from '@/components/layouts/CheckList.vue';
import Textfield from '@/components/layouts/Textfield.vue';
import TitleBar from '@/components/layouts/TitleBar.vue';
import Select from '@/components/layouts/Select.vue';
import Paginationv2 from '@/components/layouts/Paginationv2.vue';
import Button from '@/components/layouts/Button.vue';
import Dialog from '@/components/layouts/Dialog.vue';
import ReasonsSelection from '../verification/listing/ReasonsSelection.vue';
import moment from 'moment';
import User from '@/models/user';
import { arraySort_byStringKeyword } from '@/utils/array';
// import { get } from '@vueuse/core';

export default {
    name: 'admin-auto-capture-management',
    components: {
        'ar-titlebar': TitleBar,
        'ar-textfield': Textfield,
        'ar-check-list': CheckList,
        'ar-select': Select,
        'ar-pagination-v2': Paginationv2,
        'ar-button': Button,
        'ar-dialog': Dialog,
        'ar-reason-selection': ReasonsSelection,
    },
    async mounted() {
        this.initializeSelectionCache()
        await this.get()
        await this.getStatus_count()
        // await this.getCampaigns()
        await this.getResearchers()
        await this.getQA()
        await this.getCampaigns_inAutoCapture()
        await this.getResearchers_inAutoCapture()
    },
    data() {
        return {
            search: '',
            selectAll: false,
            table_order: {
                column: null,
                order: null,
            },

            // pagination
            page: 1,
            page_size: 15,
            visible_total: 0,
            visible: 0,
            last_page: 1,
            total: 1,
            
            // sortby
            sortBy: '',

            hovered_index: -1,
            
            selectedAutoCaptures: {},
            selected_autoCapture: {},
            selectedAutoCaptures_data: {},
            autoCaptures: [],
            campaign: 0,
            campaigns: [],
            campaigns_inAutoCapture: [],

            researcher: 0,
            researchers: [],
            researchers_inAutoCapture: [],
            researcher_modal_search: '',
            selected_reassign_researcher: {},

            qa: 0,

            allResearchers: [],
            allQAs: [],

            showNumberOfAutoCaptures_options: [{
                label: 'Show 15 Items',
                value: 15,
            }, {
                label: 'Show 30 Items',
                value: 30,
            }, {
                label: 'Show 50 Items',
                value: 50,
            }, {
                label: 'Show 100 Items',
                value: 100,
            }, {
                label: 'Show 150 Items',
                value: 150,
            }],

            searchBy: 0,
            searchBy_options: [{
                label: 'Search by Seller Name',
                value: 0,
            }, {
                label: 'Search by Seller URL',
                value: 1,
            }, {
                label: 'Search by Product URL',
                value: 2,
            }, {
                label: 'Search by LID',
                value: 3,
            }, {
                label: 'Search by Date Captured',
                value: 4,
            }],


            status: 0,
            statuses: [{
                label: 'All Statuses',
                value: 0,
                color: '#FFFFFF',
                id: 0,
            }, {
                label: 'Pending',
                value: 'Pending',
                color: '#ffbfac',
                id: 1,
            }, {
                label: 'Processing',
                value: 'Processing',
                color: '#84a8e1',
                id: 2,
            }, {
                label: 'Completed With Issues',
                value: 'Completed_With_Issues',
                color: '#f59e9e',
                id: 3,
            }, {
                label: 'Failed',
                value: 'Failed',
                color: '#EE2B2B',
                id: 4,
            }, {
                label: 'Success',
                value: 'Success',
                color: '#b9e7cf',
                id: 5,
            }],

            reassignStatuses:  {
                loading: false,
                results: [],
                selected: 0,
                data: [{
                    label: "Work In Progress",
                    value: 4,
                }, 
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */
                // {
                //     label: "For Rework",
                //     value: 2,
                // },
                {
                    label: "Invalid",
                    value: 99,
                },
                {
                    label: "Researched",
                    value: 1,
                }]
            },
            individualReassign_hasError: false,
            reassignment_mode: 'Researcher',
            selected_campaign_user: 0,

            predefined_reasons: [],
            all_predefined_reasons: {},
            selected_predefined_reasons: [],
            selected_predefined_sub_reasons: [],
            other_reason: "",
            selected_other_reason: [],
            
            is_loading: false,

            successful_count: 0,
            isStatus_loading: true,

            action_options: [{
                label: 'Rescan',
                value: 0,
                logo: '/static/svg/admin/Auto Capture Management/action-rescan-new-active.svg',
            }, {
                label: 'Revert to Researcher',
                value: 1,
                logo: '/static/svg/admin/Auto Capture Management/action-revert-active.svg',
            }, {
                label: 'Reassign Researcher',
                value: 2,
                logo: '/static/svg/admin/Auto Capture Management/action-reassign-active.svg',
            }, {
                label: 'Delete',
                value: 3,
                logo: '/static/svg/admin/Auto Capture Management/action-delete-active.svg',
            }],
            action: -1,
            hasClicked_actionOptions: false,
            actionOption_Ref: null,
            hasShowClass: false,

            date_capture: {
                filterDateCaptured: '',
                from: '',
                to: '',
            },
            min_date_added: "",
            max_date_added: "",
        }
    },
    methods: {
        ...mapActions(["refreshCancelToken", "cancelRequests"]),
        async clearKeywords() {
            this.search = ''
        },
        async updateTableOrder(column) {
            this.table_order.order = this.table_order.column == column ? ['asc', 'desc'][+(this.table_order.order == 'asc')] : 'asc';
            this.table_order.column = column;
            // (campaign, listing_id, seller_name, seller_url, url, researcher, status)
            switch(column) {
                case 'campaign':
                    this.sortBy = 'campaign'
                    break
                case 'listing_id':
                    this.sortBy = 'listing_id'
                    break
                case 'seller_name':
                    this.sortBy = 'seller_name'
                    break
                case 'seller_url':
                    this.sortBy = 'seller_url'
                    break
                case 'product_url':
                    this.sortBy = 'url'
                    break
                case 'researcher':
                    this.sortBy = 'researcher'
                    break
                case 'date_captured':
                    this.sortBy = 'date_captured'
                    break
                case 'status':
                    this.sortBy = 'status'
                    break
            }

            if(this.table_order.order == 'desc') {
                this.sortBy = `-${this.sortBy}`
            }
            showBackdrop()
            await this.get()
            hideBackdrop()
        },
        multiSelect() {
            let _autoCaptures = [...this.filtered_autoCaptures.result]
            if((this.last_page == this.page && this.selectedAutoCaptures[this.page].length == this.autoCaptures.length) || this.selectedAutoCaptures[this.page].length == this.page_size) {
                this.selectedAutoCaptures[this.page] = []
                _autoCaptures.forEach(ac => {
                    delete this.selectedAutoCaptures_data[ac.id]
                })
            } else {
                this.selectedAutoCaptures[this.page] = this.filtered_autoCaptures.result.map(r => r.id)
            
                _autoCaptures.forEach(ac => {
                    this.selectedAutoCaptures_data[ac.id] = ac
                })
            }
            // this.selectedAutoCaptures[this.page] = (this.last_page == this.page && this.selectedAutoCaptures[this.page].length == this.autoCaptures.length) || this.selectedAutoCaptures[this.page].length == this.page_size ? [] : this.filtered_autoCaptures.result.map(r => r.id)
        },
        initializeSelectionCache() {
            for (let i = 0; i < this.last_page; i++) {
                this.selectedAutoCaptures = { ...this.selectedAutoCaptures, [i+1]: [] }
            }
            this.selectedAutoCaptures_data = {}
        },
        initializeSelectAll() {
            this.selectAll = (this.last_page == this.page && this.selectedAutoCaptures[this.page].length == this.autoCaptures.length) || this.selectedAutoCaptures[this.page].length == this.page_size
        },
        async get(requestFrom = 'mounted') {
            this.cancelRequests();
            this.refreshCancelToken();

            let searchBy = parseInt(this.searchBy)
            let _search = this.search.trim()

            if(requestFrom == 'pagination' || requestFrom == 'filterBy') {
                showBackdrop()
            }

            let queries = {
                filter: {
                    date_captured: {
                        from: '',
                        to: '',
                    }
                },
                "include": [
                    "listing.user",
                    "campaign",
                    "listing.seller",
                    "listing.info",
                ],
                "fields": {
                    "crawl_queries": "id,listing_id,campaign_id,url,status,keyword",
                    "campaigns": "id,name",
                    "listing.sellers": "id,name,url",
                    "listings": "id,seller_id,url,user_id",
                    "listing.users": "id,first_name,last_name"
                },
                "includeFilter": {
                    "listing.info": {
                        field: "date_captured",
                    }
                },
                sort: this.sortBy,//Sort by column (campaign, listing_id, seller_name, seller_url, url, researcher, status)
                page_size: this.page_size, //Pagination
                page: this.page, //Pagination
            };
            
            // "filter": {
            //     "campaign_id": null, //Filter by Campaign
            //     "listing.user_id": null, //Filter by Researcher
            //     "status": "completed_with_issues", //Filter by Status | Values are PENDING, PROCESSING, SUCCESS, FAILED, COMPLETED_WITH_ISSUES
            //     "listing_id": 123, //Filter by Listing ID
            //     "listing.seller.name": "datong", //Filter by Seller Name
            //     "url": "https://www......", //Filter by Product URL
            //     "listing.seller.url": "A2TUGFIRP3D6CE" //Filter by Seller URL
            // }
            
            if (this.campaign != 0)
                queries.filter.campaign_id = this.campaign
            
            if (this.researcher != 0)
                queries.filter["listing.user_id"] = this.researcher

            if (this.qa != 0)
                queries.filter["listing.qa_user_id"] = this.qa

            if (this.status != 0)
                queries.filter.status = this.status
            
            if (this.min_date_added == '')
                delete queries.filter.date_captured.from

            if (this.max_date_added == '' || this.min_date_added == '' && this.max_date_added == this.today)
                delete queries.filter.date_captured.to

            switch (searchBy) {
                case 0:
                    queries.filter["listing.seller.name"] = _search
                    break;
                case 1:
                    queries.filter["listing.seller.url"] = _search
                    break;
                case 2:
                    queries.filter.url = _search
                    break;
                case 3:
                    queries.filter.listing_id = _search
                    break;
                case 4:
                    if (this.min_date_added != '')
                        queries.filter.date_captured.from = this.min_date_added

                    if (this.min_date_added !='' && this.max_date_added != '')
                        queries.filter.date_captured.to = this.max_date_added
                    break;
                default:
                    queries.filter["listing.seller.name"] = _search
                    break;
            }

            this.$parent.showSnackbar('Fetching Auto Captures...',true)
            await axios.get(`${process.env.VUE_APP_URL}/api/crawl_queries?${qs.stringify(queries, { encode: false })}`,getHeaders()).then(
                response => {
                    if(response.status == 200) {
                        this.getStatus_count()
                        
                        this.resetReassignData()
                        this.resetActionOptionsData()
                        this.initializeSelectionCache()

                        this.autoCaptures = response.data.data

                        this.last_page = response.data.last_page
                        this.page = response.data.current_page
                        this.total = response.data.total

                        if (requestFrom == 'pagination' || requestFrom == 'filterBy') {
                            hideBackdrop()
                        }

                        this.$parent.showSnackbar('Successfully Fetch Auto Captures', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar("Something went wrong while fetching Auto Capture Data. Please contact system admin.",false, "invalid", "fas fa-times-circle")
            })
            // this.crawlQueries = response.data.data.map(crawlQuery => ({
            //     ...crawlQuery,
            //     created_at: crawlQuery.created_at && new Date(crawlQuery.created_at),
            //     crawl_ended: crawlQuery.crawl_ended && new Date(crawlQuery.crawl_ended),
            //     campaign_name: crawlQuery.campaign.name,
            //     campaign_src: this.$parent.getImageCDN('campaigns', crawlQuery.campaign.name),
            //     platform_name: crawlQuery.listing.platform.name,
            //     platform_src: this.$parent.getImageCDN('platforms', crawlQuery.listing.platform.name),
            //     seller_name: crawlQuery.listing.seller ? crawlQuery.listing.seller.name : "Seller Not Found",
            //     product_img: crawlQuery.listing.preview && crawlQuery.listing.preview[0] && crawlQuery.listing.preview[0].file_name2,
            //     listing_title: crawlQuery.listing.info && crawlQuery.listing.info[0] && crawlQuery.listing.info[0].value
            // }));
        },
        async getCampaigns_inAutoCapture() {
            // GET /api/v2/campaigns?filter[has-crawl-query]=1

            await axios.get(`${process.env.VUE_APP_URL}/api/v2/campaigns?filter[has-crawl-query]=1`,getHeaders()).then(
                response => {
                    if(response.status == 200) {
                        this.campaigns_inAutoCapture = response.data.data
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('Something went wrong fetching Campaigns. Please contact system admin', false, 'invalid', "fas fa-times-circle");
            })
        },
        async getResearchers_inAutoCapture() {
            // GET /api/v2/users?filter[has-crawl-query]=1

            await axios.get(`${process.env.VUE_APP_URL}/api/v2/users?filter[has-crawl-query]=1`,getHeaders()).then(
                response => {
                    if(response.status == 200) {
                        this.researchers_inAutoCapture = response.data.data
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('Something went wrong fetching Researchers. Please contact system admin', false, 'invalid', "fas fa-times-circle");
            })
        },
        /**
         * For individual Rescanning
         * @param {Array of Numbers} crawlQueryId 
         * @param {String} updatedKeyword 
         */
        async rescan(crawlQueryId, updatedKeyword, isIndividual = false) {
            this.$parent.showSnackbar('Rescanning...',true)
            let request = getHeaders();
            request.headers.Gui = 'Verification';
            await axios.post(`${process.env.VUE_APP_URL}/api/crawl_queries/rescan/${crawlQueryId}`, { keyword: updatedKeyword }, request).then(
                response => {
                    if(response.status == 200) {
                        if(isIndividual) {
                            this.closeModal('rescan-single-modal')
                            this.$parent.showSnackbar('Successfully Rescan Auto Capture Listing', false, 'success', 'fas fa-check-circle')
                            this.resetActionOptionsData()
                            this.initializeSelectionCache()
                            setTimeout(async _ => {
                                await this.get()
                            }, 1000)
                        }
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('Something went wrong while executing Rescan Action. Please contact system admin', false, 'invalid', "fas fa-times-circle");
            })
        },
        async batchRescan(crawlQueryIds) {
            this.closeModal('multiple-rescan-modal')
            this.$parent.showSnackbar('Rescanning...',true)
            let request = getHeaders();
            request.headers.Gui = 'Verification';
            await axios.post(`${process.env.VUE_APP_URL}/api/crawl_queries/batch-rescan`, { ids: crawlQueryIds }, request).then(
                async response => {
                    if(response.status == 200 || response.status == 204) {
                        this.$parent.showSnackbar('Successfully Executed Batch Rescan', false, 'success', 'fas fa-check-circle')
                        setTimeout(async _ => {
                            this.resetActionOptionsData()
                            this.initializeSelectionCache()
                            await this.get()
                        }, 1500)
                    }
                }
            )
            .catch(error => {
                console.log({error})
                let err = {error}
                this.$parent.showSnackbar(`${err.error.response.data.message ?? 'Something went wrong while executing Batch Rescan Action. Please contact system admin'}`, false, 'invalid', "fas fa-times-circle");
            })
        },
        async deleteAutoCapture(crawlQueryId, isIndividual = false) {
            this.$parent.showSnackbar('Deleting...',true)
            let request = getHeaders();
            request.headers.Gui = 'Verification';
            if(isIndividual)
                this.closeModal('delete-single-auto-capture-modal')
            await axios.delete(`${process.env.VUE_APP_URL}/api/crawl_queries/${crawlQueryId}`, request).then(
                async response => {
                    if(response.status == 200 || response.status == 204) {
                        if (isIndividual) {
                            this.$parent.hideSnackbar()
                            this.$parent.showSnackbar('Successfully Deleted Researcher/s', false, 'success', 'fas fa-check-circle')
                            setTimeout( async _ => {
                                this.resetActionOptionsData()
                                this.initializeSelectionCache()
                                await this.get()
                            }, 1000)
                        }
                    }
                }
            ).catch(error => {
                console.log({error})
                let err = {error}
                this.$parent.showSnackbar(`${err.error.response.data.message ?? 'Something went wrong while executing Delete Action. Please contact system admin'}`, false, 'invalid', "fas fa-times-circle");
            })
        },
        async batchDeleteAutoCapture(crawlQueryIds) {
            let ids = [...crawlQueryIds]
            let count = 0
            ids.forEach(async c => {
                // console.log(c)
                await this.deleteAutoCapture(c)
                count++
                if(count == ids.length) {
                    this.closeModal('delete-auto-capture-modal')
                    setTimeout( async _ => {
                        this.resetActionOptionsData()
                        this.initializeSelectionCache()
                        await this.get()
                    }, 2000)
                }
            })
        },
        async batchRevert(crawlQueryIds) {
            let request = getHeaders();
            request.headers.Gui = 'Verification';
            await axios.post(`${process.env.VUE_APP_URL}/api/crawl_queries/batch-researcher-revert`, { ids: crawlQueryIds }, request).then(
                async response => {
                    if(response.status == 200 || response.status == 204) {
                        this.closeModal('revert-to-researcher-modal')
                        this.closeModal('individual-revert-to-researcher-modal')
                        this.resetActionOptionsData()
                        this.initializeSelectionCache()
                        this.$parent.showSnackbar(`Successfully Reverted Researcher${crawlQueryIds.length > 1 ? 's' : ''}`, false, 'success', 'fas fa-check-circle')
                        await this.get()
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('Something went wrong while executing Revert Action. Please contact system admin', false, 'invalid', "fas fa-times-circle");
            })
        },
        openModal(modalId) {
            $(`#${modalId}`).modal('show')
        },
        closeModal(modalId) {
            $(`#${modalId}`).modal('hide')
        },
        // async getCampaigns() {
        //     if (this.campaigns.length) {
        //         return;
        //     }
        //     let headers = getHeaders();
        //     headers.headers['Where'] = '{"status": 1 }';
        //     headers.headers['Sort'] = 'name,asc';
        //     await axios
        //         .get(`${process.env.VUE_APP_URL}/campaigns-only?page_size=1000`, headers)
        //         .then(response => {
        //             this.campaigns = response.data.data;
        //         })
        //         .catch(ex => {
        //             console.log(ex);
        //             this.$parent.showSnackbar('Something went wrong fetching Campaigns. Please contact system admin', false, 'invalid', "fas fa-times-circle");
        //         });
        // },
        async getResearchers(){
            const users = User.getResearchersAndCL()

            this.allResearchers = users
            
            // let headers = getHeaders()
            // headers.headers['Where'] = '{"status": 1,"access_level": [1,5]}'
            // headers.headers['Sort'] = 'first_name,asc';
            // // headers.headers.where = JSON.stringify({'access_level': [1]})
            // await axios.get(`${process.env.VUE_APP_URL}/users/multi?page_size=1000`, headers)
            //     .then(response => {
            //         let results = response.data
            //         if(+results.meta.code === 200) {
            //             this.allResearchers = results.data
            //         }
            //     })
            //     .catch(err => this.$parent.showSnackbar(err.response.data.meta + 'Please contact system admin.', false, 'invalid', 'fas fa-times-circle') )
        },
        async getQA(){

            const users = User.getQA()
            
            this.allQAs = arraySort_byStringKeyword(users, 'first_name')

            // let headers = getHeaders()
            // headers.headers['Where'] = '{"status": 1,"access_level": [2]}'
            // headers.headers['Sort'] = 'first_name,asc';
            // // headers.headers.where = JSON.stringify({'access_level': [1]})
            // await axios.get(`${process.env.VUE_APP_URL}/users/multi?page_size=1000`, headers)
            //     .then(response => {
            //         let results = response.data
            //         if(+results.meta.code === 200) {
            //             this.allQAs = results.data
            //         }
            //     })
            //     .catch(err => this.$parent.showSnackbar(err.response.data.meta + 'Please contact system admin.', false, 'invalid', 'fas fa-times-circle') )
        },
        async getPredefinedReasons(id) {

            const qflag = this.$parent.getQFlag(id);

            /* const forReworkId = 2
            if(this.all_predefined_reasons[qflag]?.length > 0 && id !== forReworkId) { */

            if (this.all_predefined_reasons[qflag]?.length > 0) {
                this.predefined_reasons = this.all_predefined_reasons[qflag];
                // this.fetching_reasons   = true;

                return
            }

            this.is_loading = true;

            const queries = {
                filter: {
                    qflag_id: id
                },
                include: "subReasons,subReasonsCount",
                page_size: 1000
            };

            const { status, data } = await this.$http.get(`/api/v2/reasons?${qs.stringify(queries, { encode: false })}`, getHeaders());

            this.is_loading = false;

            if (status !== 200) {
                this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'invalid', 'fas fa-times-circle');

                return;
            }

            const reasons = data.data;

            this.predefined_reasons = reasons
                .filter(reason => reason.status )
                .map(item => {
                    const { id, type, severity_level, sub_reasons, reason, restricted_roles } = item;

                    const getSubReasons = () => sub_reasons
                        .filter(item => item.status)
                        .map(item => {
                            const { id, severity_level, text: reason, restricted_roles } = item;
                            return { id, severity_level, reason, restricted_roles };
                        });

                    const mainReason = sub_reasons.length ? { [reason]: getSubReasons() } : reason;

                    const WITH_SUBREASONS = 2;

                    // if (+type === WITH_SUBREASONS && mainReason[reason].length === 0) {
                    if (+type === WITH_SUBREASONS && sub_reasons?.length === 0) {
                        return null;
                    }

                    return { id, type, severity_level, restricted_roles, reason: mainReason };
                })
                .filter(item => item !== null)

            this.all_predefined_reasons[qflag]       = this.predefined_reasons;
            // this.filter_status_change_reason.options = reasons.map(reason => ({ label: reason.reason, value: reason.id }));
            // this.fetching_reasons                    = true;

            return;
        },
        setPredefinedReasons() {
            const reasons     = this.$store.state.checked_reasons;
            const reasonsInfo = this.$store.state.checked_subreasons;
            const otherReason = this.$store.state.checked_other_reason[0] ?? null;
            const comment     = this.$store.state.other_reason_comment;

            let selectedReasons = [];

            if (reasons.length) {
                const tempReasons = reasons.map(reason => ({ id: reason.id, reasons_info: [], other_reason: '' }));
                selectedReasons = [ ...selectedReasons, ...tempReasons ];
            }

            if (reasonsInfo.length) {
                const reasonIds = [ ...new Set(reasonsInfo.map(({ reason_id }) => reason_id)) ];

                for (const id of reasonIds) {
                    const filteredReasonsInfo = reasonsInfo.filter(ri => ri.reason_id === id).map(ri => ri.id);
                    const tempReasonsInfo     = { id, reasons_info: filteredReasonsInfo, other_reason: '' };

                    selectedReasons = [ ...selectedReasons, tempReasonsInfo ];
                }
            }

            if (otherReason !== null) {
                const tempOther = { id: otherReason, reasons_info: [], other_reason: comment };
                selectedReasons = [ ...selectedReasons, tempOther ];
            }

            return selectedReasons;
        },
        /**
         * 
         * @param { Number } lid 
         * @param { Number } qflag 
         * @param { Number } user_id 
         * @param { Number } autoCapture_id 
         */
        async reassignResearcher(lid, qflag, user_id, autoCapture_id){
            let payload = {}

            //Always set comment to empty since there is predefined-reason to include
            payload.comment = ''
            payload.reasons = this.setPredefinedReasons()
            const request = getHeaders();
            request.headers.Gui = 'Verification';
            request.headers["Requested-From"] = "ARTEMIS Admin - Auto Capture Management";
            await axios.patch(`${process.env.VUE_APP_URL}/listings/${lid}/statuschange/reassignment/${+qflag}/${+user_id}/0`,payload, request).then(
                response => {
                    if(response.data.meta.code == 200)
                        this.successful_count++
                }
            ).catch(error => {
                console.log(error)
                this.closeModal('reassign-modal')
                // this.$parent.showSnackbar('Something went wrong while Reassigning Researcher. Please contact system admin', false, 'invalid', "fas fa-times-circle");
                let _reassignStatuses = this.reassignStatuses
                let seller
                if(this.hasClicked_actionOptions) {
                    seller = this.selected_autoCapture?.listing?.seller?.name
                } else {
                    seller = this.selectedAutoCaptures_data[autoCapture_id]?.listing?.seller?.name
                }

                _reassignStatuses.results.push({
                    reason: { message: new Error(error.response.data.meta.message), lid , seller},
                    status: 'rejected'
                })
                Vue.set(this.reassignStatuses, 'results', _reassignStatuses.results)
                setTimeout( _ => {
                    this.openModal('reassign-modal')
                }, 1000)
            })
        },
        async batchReassign(qflag, user_id) {
            let autoCaptures = []
            this.successful_count = 0
            
            if(this.hasClicked_actionOptions) {
                autoCaptures.push(this.reassignResearcher(this.selected_autoCapture.listing_id, qflag, user_id, this.selected_autoCapture.id))
            } else {
                Object.entries(this.selectedAutoCaptures_data).map(async ([key, val]) => {
                    autoCaptures.push(this.reassignResearcher(val.listing_id, qflag, user_id, val.id))
                })
            }
            await Promise.allSettled(autoCaptures);
        
            if(this.successful_count > 0) {
                this.resetActionOptionsData()
                this.closeModal('reassign-modal')
                this.$parent.showSnackbar(`Successfully Reassigned Researcher/s`, false, 'success', 'fas fa-check-circle')
                await this.get()
            }
        },
        resetReassignData() {
            this.reassignStatuses.selected = 0

            $('#reassign-status-select .input-icon')?.click()

            this.reassignStatuses.results = []
            this.selected_reassign_researcher = {}
            this.$store.dispatch('resetPredefinedReasonState')
        },
        resetActionOptionsData() {
            this.hasClicked_actionOptions = false
            this.action = -1
        },
        async getStatus_count() {
            this.isStatus_loading = true

            let searchBy = parseInt(this.searchBy)
            let _search = this.search.trim()

            let statuses = [...this.statuses]
            let counts = []
            let count = 0

            const status_count = async (status, status_option_id) => {
                let _status = status == 'completed with issues'? 'completed_with_issues' : status

                let queries = {
                    filter: {
                        date_captured: {
                            from: '',
                            to: '',
                        }
                    },
                    page_size: 1,
                }

                queries.filter.status = _status

                if (this.campaign != 0)
                    queries.filter.campaign_id = this.campaign
                
                if (this.researcher != 0)
                    queries.filter["listing.user_id"] = this.researcher

                if (this.min_date_added == '')
                    delete queries.filter.date_captured.from

                if (this.max_date_added == '' || this.min_date_added == '' && this.max_date_added == this.today)
                    delete queries.filter.date_captured.to

                switch (searchBy) {
                    case 0:
                        queries.filter["listing.seller.name"] = _search
                        break;
                    case 1:
                        queries.filter["listing.seller.url"] = _search
                        break;
                    case 2:
                        queries.filter.url = _search
                        break;
                    case 3:
                        queries.filter.listing_id = _search
                        break;
                    case 4:
                        if (this.min_date_added != '')
                            queries.filter.date_captured.from = this.min_date_added

                        if (this.min_date_added !='' && this.max_date_added != '')
                            queries.filter.date_captured.to = this.max_date_added
                        break;
                    default:
                        queries.filter["listing.seller.name"] = _search
                        break;
                }

                await axios.get(`${process.env.VUE_APP_URL}/api/crawl_queries?${qs.stringify(queries, { encode: false })}`,getHeaders()).then(
                    response => {
                        counts[status_option_id] = (response.data.total)
                        count++
                    }
                ).catch(error => {
                    this.$parent.showSnackbar('Something went wrong in getting Status Counts. Please contact system admin.', false, 'invalid', 'fas fa-times-circle');
                    console.log(error)
                })
            }
            let promises = []
            statuses.forEach(async s => {
                    if(s.label.toLowerCase() != 'all statuses')
                        promises.push(status_count(s.label.toLowerCase(), s.id))
                }
            )

            await Promise.allSettled(promises)
            
            if(count+1 == counts.length) {
                this.statuses = statuses.map( (s,index) => {
                    return {
                        ...s,
                        count: s.id == 0 ? this.total : counts[s.id],
                    }
                })
                this.isStatus_loading = false
            }
        },
        action_openModal(to) {
            switch (to) {
                case 0:
                    this.openModal('rescan-single-modal')
                    break;
                case 1:
                    this.openModal('individual-revert-to-researcher-modal')
                    break;
                case 2:
                    this.openModal('reassign-modal')
                    break;
                case 3:
                    this.openModal('delete-single-auto-capture-modal')
                    break;
            }
        },
        async setActionOptionRef(index) {
            this.actionOption_Ref = await this.$refs.actionOptionRef[index]
            this.hasShowClass = !this.actionOption_Ref?.classList?.value?.includes('show')
            this.hasClicked_actionOptions = this.hasShowClass
            if(!this.hasShowClass) {
                this.selected_autoCapture = {}
            }
        },
        errorCampaignImage(event) {
            event.target.src = '/static/svg/Campaign Default Photo.svg'
        },
    },
    computed: {
        filtered_autoCaptures() {
            let autoCaptures = this.autoCaptures
            // filter by campaigns
            if(this.campaign != 0) {
                autoCaptures = autoCaptures.filter(c => c?.campaign?.id == this.campaign)
            }
            
            // filter by researchers
            if(this.researcher != 0) {
                autoCaptures = autoCaptures.filter(c => c?.listing?.user?.id == this.researcher)
            }

            // filter by status
            if(this.status != 0) {
                autoCaptures = autoCaptures.filter(c => c?.status?.toLowerCase() == this.status?.toLowerCase())
            }

            // search
            // if(this.search.trim() != '') {
            //     autoCaptures = autoCaptures?.filter(c => 
            //         // c?.platform?.name.toLowerCase().includes(this.search.toString().toLowerCase()) || 
            //         c?.campaign?.name?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.listing?.id?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.listing?.seller_id?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.listing?.seller?.name?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.listing?.seller?.url?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.listing?.user?.first_name?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.listing?.user?.last_name?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.listing?.url?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase()) ||
            //         c?.status?.toString().toLowerCase().includes(this.search.toString().trim().toLowerCase())
            //     )
            // }
            let result = autoCaptures
            let visible_total = autoCaptures.length
            let visible = result.length

            // return autoCaptures
            return { result, visible_total, visible }
        },
        from_page_count() {
            return (this.page * parseInt(this.page_size)) - parseInt(this.page_size) + 1;
        },
        to_page_count() {
            return (this.page * parseInt(this.page_size)) - (parseInt(this.page_size) - this.visible);
        },
        selectedAutoCapturesClean() {
            let ids = []
            Object.values(this.selectedAutoCaptures).forEach(c => ids.push(...c))
            return ids
        },
        hasSelection() {
            return this.selectedAutoCapturesClean.length
        },
        modified_campaignForFilterBy() {
            // let campaigns = []
            let campaigns = this.campaigns_inAutoCapture.map(c => ({label: c.name, value: c.id, logo: this.$parent.getImageCDN('campaigns', c.name || 'Default')}))
            campaigns = [{value: 0, label: 'All Campaigns', logo: '/static/svg/Nav_Campaign_Active.svg'}, ...campaigns]
            return campaigns
        },
        modified_researcherForFilterBy() {
            // let researchers = this.researchers.filter(c => c.status).map(c => ({label: c.first_name + ' ' + (c.last_name != null ? c.last_name : ''), value: c.id, logo: '/static/svg/Auto Campaign Admin/researchers.svg'}))
            let researchers = this.researchers_inAutoCapture.map(c => ({label: c.first_name + ' ' + (c.last_name != null ? c.last_name : ''), value: c.id, logo: '/static/svg/Auto Campaign Admin/researchers.svg'}))
            researchers = [{value: 0, label: 'All Researchers', logo: '/static/svg/Auto Campaign Admin/researchers.svg'}, ...researchers]
            return researchers
        },
        modified_QAforFilterBy() {
            let QAs = this.allQAs.map(aqa => ({label: aqa.first_name + ' ' + (aqa.last_name != null ? aqa.last_name : ''), value: aqa.id, logo: '/static/svg/Auto Campaign Admin/researchers.svg'}))
            QAs = [{value: 0, label: 'All QAs', logo: '/static/svg/Auto Campaign Admin/researchers.svg'}, ...QAs]
            return QAs
        },
        selected_campaign() {
            return this.modified_campaignForFilterBy.filter(c => c.value == this.campaign)[0]?.label
        },
        selected_researcher() {
            return this.modified_researcherForFilterBy.filter(c => c.value == this.researcher)[0]?.label
        },
        selected_QA () {
            return this.modified_QAforFilterBy.filter(qa => qa.value == this.qa)[0]?.label
        },
        selected_status() {
            return this.statuses.filter(s => s.value == this.status)[0]?.label
        },
        // campaigns_inAutoCapture() {
        //     let campaigns = []
        //     let autocaptures = [...this.autoCaptures]

        //     campaigns = autocaptures.map(ac => {                
        //         return {
        //             id: ac?.campaign?.id,
        //             name: ac?.campaign?.name
        //         }
        //     })

        //     campaigns = [...new Map(campaigns.map((c) => [c.id, c])).values()]
        //     return campaigns
        // },
        // researchers_inAutoCapture() {
        //     let researchers = []
        //     let autocaptures = [...this.autoCaptures]

        //     researchers = autocaptures.map(ac => {
        //         return {
        //             id: ac?.listing?.user?.id,
        //             name: `${ac?.listing?.user?.first_name} ${ac?.listing?.user?.last_name}`
        //         }
        //     })
        //     researchers = [...new Map(researchers.map((r) => [r.id, r])).values()]
        //     return researchers
        // },
        // isReassignWithReason() {
        //     /* return (this.individualReassign.status.selected && ([2,8,3,98].includes(this.currentStatus) || !([4].includes(this.individualReassign.status.selected) && [1, 99].includes(this.currentStatus)) || ([4].includes(this.individualReassign.status.selected) && this.access_level != 1))) 
        //         || (this.reassignStatuses.selected && ([2,8,3,98].includes(this.currentStatus) || !([4].includes(this.reassignStatuses.selected) && [1, 99].includes(this.currentStatus)) || ([4].includes(this.reassignStatuses.selected) && this.access_level != 1))) */

        //     // return !this.isFeatureRestricted('v-modal-reasons-selection');
        //     return !this.isFeatureRestricted('v-reassignment-reasons-selection');
        // },
        reassignStatusOptions() {
            let statuses = [
                { label: 'Work in Progress', value: ListingStatus.WIP },
                { label: 'Invalid', value: ListingStatus.Invalid },
                { label: 'Researched', value: ListingStatus.Researched },
                { label: 'Qualified', value: ListingStatus.Qualified },
                { label: 'Not Covered', value: ListingStatus.NotCovered },
                // { label: 'Archived', value: ListingStatus.Archived },
            ];

            /**
             * Remove one status from the statuses with the given parameter.
             * @param { number } statusToRemove 
             */
            const filterStatus = (statusToRemove) => {
                const filteredStatuses = statuses.filter(status => status.value !== statusToRemove);
                return filteredStatuses;
            }

            if (this.isFeatureRestricted('a-reassignment-auto-capture-researched-status')) {
                statuses = filterStatus(ListingStatus.Researched);
            }

            if (this.isFeatureRestricted('a-reassignment-auto-capture-qualified-status')) {
                statuses = filterStatus(ListingStatus.Qualified);
            }

            if (this.isFeatureRestricted('a-reassignment-auto-capture-not-covered-status')) {
                statuses = filterStatus(ListingStatus.NotCovered);
            }

            return statuses;
        },
        isReassignWIP() {
            return this.reassignStatuses.selected && [4].includes(this.reassignStatuses.selected)
        },
        filtered_allResearchers() {
            let _allResearchers = this.allResearchers
            let search = this.researcher_modal_search?.toString().toLowerCase()

            if(search != '') {
                _allResearchers = _allResearchers.filter(
                    ar =>
                        ar?.first_name?.toString().toLowerCase().includes(search) || 
                        ar?.last_name?.toString().toLowerCase().includes(search) ||
                        ar?.id?.toString().toLowerCase().includes(search) 
                )
            }

            return _allResearchers
        },
        isDisableToReassign() {
            let researcher = this.selected_reassign_researcher.id
            let status = this.reassignStatuses.selected 
            let reasons = this.setPredefinedReasons()

            let count = 0
            let isDisable = false

            if(researcher === null || researcher === undefined )
                count++

            if(status == 0 || status === null || status === undefined)
                count++

            if(reasons == [] || reasons.length == 0)
                count++

            if(count > 0)
                isDisable = true

            return isDisable
        },
        today() {
            // let date = new Date().toISOString().split('T')[0]
            // // Added format for implementation of new Endpoints
            // let date2 = new Date(new Date().getTime())
            // let year = date2.getFullYear()
            // date2 = date2.toString().split(year.toString())[1]
            // date2 = date2.split(' ')[1]
            // date = `${date} ${date2}`
            // /**
            //  * Previous Code
            //  */
            return new Date().toISOString().split('T')[0]
            // return date
        },
    },
    watch: {
        async search(val) {
            if(val == '') {
                await this.get()
            }
        },
        searchBy(val) {
            if(val != 4) {
                this.min_date_added = ''
                this.max_date_added = ''
            }
        },
        last_page() {
            this.initializeSelectionCache();
        },
        filtered_autoCaptures(val) {
            this.visible = val.visible
            this.visible_total = val.visible_total
        },
        selectedAutoCaptures: {
            handler() {
                this.initializeSelectAll()
            },
            deep: true
        },
        async page() {
            this.initializeSelectAll();
            await this.get('pagination')
        },
        async page_size() {
            await this.get()
        },
        async campaign() {
            this.page = 1
            await this.get('filterBy')
        },
        async researcher() {
            this.page = 1
            await this.get('filterBy')
        },
        async qa() {
            this.page = 1
            await this.get('filterBy')
        },
        async status() {
            this.page = 1
            await this.get('filterBy')
        },
        'reassignStatuses.selected'(val) {
            this.getPredefinedReasons(val)
        },
        '$store.state.checked_reasons'(val) {
            this.selected_predefined_reasons = val
        },
        '$store.state.checked_subreasons'(val) {
            this.selected_predefined_sub_reasons = val
        },
        '$store.state.checked_other_reason'(val) {
            this.selected_other_reason = val
        },
        '$store.state.other_reason_comment'(val) {
            this.other_reason = val
        },
        async min_date_added(val) {
            // // Adding time to Date
            // console.log(val)
            // let date2 = new Date(val)
            // console.log(date2)
            // let year = date2.getFullYear()
            // date2 = date2.toString().split(year.toString())[1]
            // date2 = date2.split(' ')[1]
            // val = `${val} ${date2}`
            // console.log(val)
            if (val == '') {
                this.max_date_added = ''
            } else if (this.max_date_added == '') {
                this.max_date_added = this.today
            } else if (val > this.max_date_added) {
                this.max_date_added = val
            }

            if(this.max_date_added != '' && this.min_date_added != '') {
                await this.get()
            }
        },
        async max_date_added(val) {
            if (val == '' && this.min_date_added == '') {
                this.max_date_added = this.today
            } else if (val <= this.min_date_added) {
                this.min_date_added = val
            }
            await this.get()
        },
    },
}
</script>
<style scoped>
.auto-capture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 57px 0px 0px 60px;
    /* width: calc(100% - 60px);
    height: calc(100vh - 57px); */
    width: 100%;
    height: 100vh;
    /* border: 1px solid red; */
    overflow: auto;
}
.auto-capture-management-titlebar {
    width: 100%;
    height: auto;
    /* border: 1px solid red; */
    background: #FFFFFF;
    position: relative;
    z-index: 4;
}

.has-bullet-separator {
    position: relative;
}

.has-bullet-separator::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 2;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 5px;
    width: 5px;
    border-radius: 100%;
}

/* main  */

.main-con {
    width: 100%;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    padding: 20px 20px 10px 20px;
}

.main-con table tr {
    font-size: 12px;
}

.main-con table thead tr {
    background: #53606D26;
    /* border-bottom: 2px solid rgb(214, 214, 216); */
    color: #202229;
    font-size: 11px;
}

.main-con table thead th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    white-space: nowrap;
}

.main-con table tbody tr {
    font-weight: bold;
    border-bottom: 2px solid #F5F6FA;
    vertical-align: middle;
    padding: 5px 0px;
}

.main-con table tbody .researcher-own-campaigns {
    max-height: 0;
    overflow: hidden;
    transition: all ease .5s;
    -webkit-transition: all ease .5s;
}

.main-con table tbody .researcher-own-campaigns .border-bottom {
    border-bottom: 2px solid #F5F6FA !important;
}
.main-con table tbody:last-child tr {
    border-bottom: none;
}

.main-con table tfoot tr {
    background: #53606D26;
}

.main-con table tfoot th {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.main-con table tfoot th.bigger-font {
    font-size: 14px;
}

.main-con table tr td .r-name {
    top: 2.5px;
}
/* table-item */
.table-item {
    background: #FFFFFF;
}
.table-item.isHovered {
    background: #FBF9FA;
}
.table-item.isClickedAction {
    background: #bf94b18f;
}
.table-scroll{
    width:100%;
    display: block;
    empty-cells: show;
    background: #ffff;
    color: #515365;
}

.table-scroll thead,
.table-scroll tfoot{
    position:relative;
    display: block;
    width:100%;
}

.table-scroll tbody{
    display: block;
    position:relative;
    width:100%;
    height: calc(100vh - 300px);
    /* min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px); */
    overflow-y:auto;
}

.table-scroll tr{
    width: 100%;
    display: inline-block;
}

.table-scroll > tbody > tr {
    height: 42.22px;
}

.table-scroll td,.table-scroll th{
    display: inline-block;
    word-break: break-all;
    padding: .6rem 7px;
    text-align:left;
}

.table-scroll td {
    padding: .4rem 7px;
}

.table-scroll thead th,
.table-scroll tfoot th {
    font-size: 11px;
}

.sorting-caret-con {
    width: 9px;
    height: 9px;
}

.sorting-caret {
    color: #53606D;
    font-size: 13px;
    position: absolute;
    left: 0;
    right: 0;
}

.sorting-caret-con .sorting-caret.top-caret {
    bottom: 2px;
}

.sorting-caret-con .sorting-caret.bottom-caret {
    top: 2px;
}

/* status */
.status {
    font-weight: bold;
    width: max-content;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 4px;
    letter-spacing: -0.2px;
    border: 1px solid transparent;
    word-break: break-word;
}
.status.pending {
    color: #FF5722;
    background: #ffbfac;
}
.status.processing {
    color: #2D599E;
    background: #84a8e1;
}
.status.completed-with-issues {
    color: #EE2B2B;
    background: #f59e9e;
    font-size: 10px;
}
.status.failed {
    color: #FFFFFF;
    background: #EE2B2B;
}
.status.success {
    color: #699880;
    background: #b9e7cf;
}
.truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* gui admin specific css */
.gui-admin .has-bullet-separator::before {
    background: #601848;
}

.gui-admin .dropdown-item {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #FFFFFF;
}
.gui-admin .dropdown-menu {
    max-height: 250px;
    overflow: auto;
    z-index: 5;
    background: #FFFFFF;
}
.gui-admin .dropdown-menu.filterby-status.show {
    width: 270px;
}
.gui-admin .dropdown-menu.show {
    max-height: 250px;
    overflow: auto;
    z-index: 5;
    background: #FFFFFF;
}
.gui-admin .dropdown-item:hover,
.gui-admin .dropdown-item:focus {
    color: #FFFFFF;
    background: #6018488F;
}

/* end gui admin specific css */

.filter-bar {
    padding-right: 20px;
    height: 48px;
    width: 100%;
    -webkit-transition: border-color .5s ease-in;
    transition: border-color .5s ease-in;
}
.selection-bar {
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
}

.selection-bar.has-selection {
    border-color: #601848;
    font-size: 12px;
    padding: 0 20px;
}

.combo-btn {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
    color: #1a1a1a;
}

.combo-btn img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
}

.combo-btn.rescan {
    color: #601848;
}

.combo-btn.revert {
    color: #2D599E;
}

.combo-btn.delete {
    color: #DC3C40;
}
.combo-btn.reassign {
    color: #FF835B;
}

.combo-btn:hover {
    opacity: .7;
}

.has-selection-selected-count {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    color: #601848;
    display: inline-flex;
    align-items: center;
}

.has-selection-selected-count img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
}

.modified-textarea {
    width: 100%;
    height: auto;
    margin: 0;
}

/* Reassign Modal */
#reassign-modal .modal-dialog {
    -webkit-transition: .3s ease !important;
    transition: .3s ease !important;
}
#reassign-modal .withReason .campaign-img {
    height: 40px;
    width: 40px;
}
#reassign-modal .withReason .left-con {
    width: 330px;
    height: 340px;
}
#reassign-modal .withReason .reassign-right-con {
    width: calc(100% - 368px);
    /* background: #F8F8F8; */
    padding: 15px;
}
#reassign-modal .withReason .right-con {
    height: 410px;
    overflow-y: auto;
    padding-right: 10px;
}
#reassign-modal .withReason .reassign-left-heading h5,
#reassign-modal .withReason .reassign-right-heading h5 {
    font-weight: 500;
    font-size: 16px;
}
#reassign-modal .withReason .reassign-left-heading h5 span,
#reassign-modal .withReason .reassign-right-heading h5 span {
    font-weight: 400;
    font-size: 12px;
}
.reassign-to-wip-notice {
    height: 87px;
    border: 1px dashed #ff971d;
    border-radius: 10px;
    position: relative;
    padding: 12px 20px 20px 124px;
    background: #fdf7f0;
}
.reassign-to-wip-notice .notice-img {
    width: 85px;
    height: 63px;
    position: absolute;
    left: 21px;
    top: 50%;
    /* overflow: hidden; */
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    border: 1px dashed transparent;
}
.reassign-to-wip-notice .notice-img img {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 32%;
    left: 50%;
}
.reassign-to-wip-notice h6 {
    background: #ff971d;
    border-radius: 14px;
    line-height: 25px;
    height: 24px;
    width: 75px;
    text-align: center;
    color: #ffff;
    font-size: 11px;
    margin-bottom: 10px;
}
.reassign-to-wip-notice p {
    text-align: left;
    font-size: 13px;
    color: #000000CC;
}
#reassign-modal .table-scroll {
    width:100%;
    display: block;
    empty-cells: show;
}
#reassign-modal .table-scroll thead,
#reassign-modal .table-scroll tfoot{
    position:relative;
    display: block;
    width:100%;
}
#reassign-modal .table-scroll tbody{
    display: block;
    position:relative;
    width:100%;
    height: 280px;
    overflow-y:auto;
}
#reassign-modal .table-scroll tr{
    width: 100%;
    display: inline-block;
}
#reassign-modal .table-scroll td,.table-scroll th{
    display: inline-block;
    word-break: break-all;
    padding: 1rem;
    text-align:left;
}
#reassign-modal .table-scroll tr td .status {
    display: inline-block;
    border-radius: 4px;
    padding: 3px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 500;
    background: #29ABA2;
    min-width: 66px;
}
#reassign-modal .table-scroll tr td .status.not_found {
    background: #DC3C40;
}
#reassign-modal .table-scroll tr td .status.wip {
    background: #f3a17a;
}
#reassign-modal .table-scroll tr td .status.others {
    background: #3c97dc;
}
#reassign-modal .table-scroll th {
    display: inline-block;
    color: #627aad;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.4px;
}
/* end Reassign Modal */

.status-count {
    min-width: 20px;
    width: auto;
    height: 20px;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 4px 7px;
    font-size: 12px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
}
.status-count.all-statuses {
    /* color: #FF5722;
    background: #ffbfac; */
    border: 1px solid white;
}
.status-count.pending {
    color: #FF5722;
    background: #ffbfac;
}
.status-count.processing {
    color: #2D599E;
    background: #84a8e1;
}
.status-count.completed-with-issues {
    color: #EE2B2B;
    background: #f59e9e;
}
.status-count.failed {
    color: #FFFFFF;
    background: #EE2B2B;
}
.status-count.success {
    color: #699880;
    background: #b9e7cf;
}
.action-options {
    z-index: 999;
}
</style>